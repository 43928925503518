<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-card-title class="text-left mb-1">
            <!-- {{ creatorData.username }} -->
            Johndoe212
          </b-card-title>
        </b-col>
        <b-col cols="6">
          <b-card-title class="text-right mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Approve
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              class="mr-1"
            >
              Deny
            </b-button>
          </b-card-title>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-row>

              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="First Name"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder="First Name"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- email -->
              <b-col cols="12">
                <b-form-group
                  label="Email"
                  label-for="vi-email"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-email"
                      type="email"
                      placeholder="Email"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- mobile -->
              <b-col cols="12">
                <b-form-group
                  label="Mobile"
                  label-for="vi-mobile"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SmartphoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-mobile"
                      type="number"
                      placeholder="Mobile"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- password -->
              <b-col cols="12">
                <b-form-group
                  label="Password"
                  label-for="vi-password"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-password"
                      type="password"
                      placeholder="Password"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-row>

              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="First Name"
                  label-for="vi-first-name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-first-name"
                      placeholder="First Name"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- email -->
              <b-col cols="12">
                <b-form-group
                  label="Email"
                  label-for="vi-email"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-email"
                      type="email"
                      placeholder="Email"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- mobile -->
              <b-col cols="12">
                <b-form-group
                  label="Mobile"
                  label-for="vi-mobile"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SmartphoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-mobile"
                      type="number"
                      placeholder="Mobile"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- password -->
              <b-col cols="12">
                <b-form-group
                  label="Password"
                  label-for="vi-password"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-password"
                      type="password"
                      placeholder="Password"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
