<template>
  <div>
    <mlf-user-application />
  </div>
</template>

<script>
// import api from '@mlfcore/api'
// import router from '@/router'
import MLFUserApplication from '@mlfcore/components/users/profile/application.vue'

export default {
  components: {
    'mlf-user-application': MLFUserApplication,
  },
  data() {
    return {
      // profileData: { },
    }
  },
  mounted() {
    // this.initalize()
  },
  methods: {
    // initalize() {
    //   this.getData()
    // },
    // getData() {
    //   api.getCreator(`${router.currentRoute.params.id}`, {
    //     count: 10,
    //     page: 1,
    //   }).then(response => {
    //     /* eslint-disable no-console */
    //     console.log(response)
    //     const [res] = response.data
    //     this.creatorData = res
    //   }).catch(error => {
    //     /* eslint-disable no-console */
    //     console.log(error)
    //   }).then(() => {
    //     console.log(this.creatorData.avatar)
    //   })
    // },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
